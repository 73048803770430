import React, { useState, useEffect, useRef } from 'react';

const Cursor = () => {
  // const [state, setState] = useState();
  const cursorRef = useRef()
  const whiteList = ['.switch', '.close']

  useEffect(() => {
    document.body.addEventListener("mousemove", _onMouseMove)

    return () => document.body.removeEventListener("mousemove", _onMouseMove)
  }, [])

  const _onMouseMove = (e) => {
    let cursorClass = "default";

    if(e.target.closest("button") || e.target.closest("a")){
      cursorClass = "is-anchor"
    }
    
    // whiteList.forEach(el => {
    //   if(e.target.closest(el)){
    //       cursorClass = "hidden "
    //   }
    // })

    const x = e.clientX - 5
    const y = e.clientY - 5
    cursorRef.current.style.left = x+"px"
    cursorRef.current.style.top = y+"px"
    cursorRef.current.className = cursorClass
  }

  return (
    <div id="cursor" ref={cursorRef}>
      <svg height="10" width="10">
        <circle cx="5" cy="5" r="5" fill="white" />
      </svg>
    </div>
  );
}

export default Cursor;