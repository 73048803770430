import React from 'react';

const LogoAccr = () => {
  return (
    <div className="logo-accr">
      <span className="word">
        <span className="accr">a</span>
        <span className="leftover">nne</span>
      </span>
      <span className="word">
        <span className="accr">-</span>
      </span>
      <span className="word">
        <span className="accr">s</span>
        <span className="leftover">ophie</span>
      </span>
      <span className="word">
        <span className="accr">.</span>
      </span>
      <span className="word italic">
        <span className="accr">a</span>
        <span className="leftover">uclerc</span>
      </span>
    </div>
  );
};

export default LogoAccr;