import React, { useContext, useState, useEffect } from "react"
import { WrapperContext } from "./Layout"
import PubSub from "pubsub-js"
// import { Link } from "gatsby"

const Footer = () => {
  const _WrapperContext = useContext(WrapperContext)
  const {
    settings: { links },
    location,
  } = _WrapperContext

  const [isAnnJo, setIsAnnJo] = useState()

  useEffect(() => {
    // console.log(location)
    if (location.pathname.indexOf("portfolio") > -1) {
      setIsAnnJo(true)
    } else {
      setIsAnnJo(false)
    }
  }, [location])

  useEffect(() => {
    PubSub.publish("IS_ANN_JO", isAnnJo)
  }, [isAnnJo])

  const annJoLinks = [
    {
      url: "annetjohello@gmail.com",
      label: "annetjohello@gmail.com",
    },
    {
      url: "https://www.instagram.com/_annetjo/",
      label: "instagram",
    },
  ]
  // console.log(links)
  return (
    <footer>
      <nav>
        <ul className="x end-xs">
          {isAnnJo &&
            annJoLinks.map((li, i) => (
              <li key={i}>
                <a target="_blank" href={li.url}>
                  <span className="link_gradient">{li.label}</span>
                </a>
              </li>
            ))}
          {!isAnnJo &&
            links.map((li, i) => (
              <li key={i}>
                <a target="_blank" href={li.url.url}>
                  <span className="link_gradient">{li.label.text}</span>
                </a>
              </li>
            ))}
        </ul>
      </nav>
    </footer>
  )
}

export default Footer
