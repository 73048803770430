import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import { WrapperContext } from "./Layout"
import { _getLocalizedText } from "../core/utils"

const query = graphql`
  query {
    infosFr: prismicInfos(lang: { eq: "fr-fr" }) {
      data {
        ...infos
      }
    }
    infosEn: prismicInfos(lang: { eq: "en-gb" }) {
      data {
        ...infos
      }
    }
  }
`

const Infos = ({ infosActive }) => {
  const _WrapperContext = useContext(WrapperContext)
  const { lang } = _WrapperContext

  const { infosFr, infosEn } = useStaticQuery(query)
  const infos = lang === "fr-fr" ? infosFr : infosEn
  // console.log(infos)
  const {
    // title,
    texte,
    credits,
    body,
  } = infos.data
  // console.log(body)

  return (
    <div className={`infos scrollY ${infosActive ? "is-active" : ""}`}>
      <div className="container-fluid">
        <div className="about fL">
          <div className="texte ">{RichText.render(texte.raw)}</div>
        </div>
        <div className="liste">
          {body.map((item, i) => (
            <div className="depth-0" key={i}>
              <div className="row">
                <div className="col-md-1 col-xs-12 col-categorie">
                  <div className="fS">
                    {RichText.render(item.primary.categorie.raw)}
                  </div>
                </div>
                <div className="col-md-11 col-xs-12">
                  {item.items.map((_item, j) => (
                    <div className="depth-1" key={j}>
                      <div className="row">
                        <div className="col-md-2 col-xs-1">
                          <div className="fS date tac">{_item.date}</div>
                        </div>
                        <div className="col-md-6 col-xs-5">
                          <div className="fM pr1">
                            {RichText.render(_item.title.raw)}
                          </div>
                        </div>
                        <div className="col-md-3  col-xs-5">
                          <div className="fM pr1">
                            {RichText.render(_item.lieu_media.raw)}
                          </div>
                        </div>
                        <div className="col-md-1  col-xs-1">
                          <div className="fS ">{_item.ville}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="credits fS">
          <div className="row">
            <div className="col-md-1 col-xs-2">
              {_getLocalizedText("credits")}
            </div>
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-2 hidden-xs">
                  <div className="tac">2020</div>
                </div>
                <div className="col-md-6 col-xs-5 col-xs-12">
                  <div>
                    {_getLocalizedText("design")} :{" "}
                    <a href="https://www.joanna-spadiliero.com" target="_blank">
                      <span className="link_gradient">Joanna Spadiliero</span>
                    </a>
                  </div>
                  <div>
                    Code :{" "}
                    <a href="https://ahmedghazi.com" target="_blank">
                      <span className="link_gradient">Ahmed Ghazi</span>
                    </a>
                  </div>
                  {credits && RichText.render(credits.raw)}
                </div>
                <div className="col-md-3 col-xs-5"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Infos
