import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import PubSub from "pubsub-js"
import { RichText } from "prismic-reactjs"
import { _localizedSlug } from "../core/utils"
import { WrapperContext } from "./Layout"

const query = graphql`
  query {
    navFr: prismicMenu(lang: { eq: "fr-fr" }) {
      ...menu
    }
    navEn: prismicMenu(lang: { eq: "en-gb" }) {
      ...menu
    }
  }
`

const Menu = ({ navActive }) => {
  const _WrapperContext = useContext(WrapperContext)
  const { lang, template, location } = _WrapperContext

  const { navFr, navEn } = useStaticQuery(query)
  const nav = lang === "fr-fr" ? navFr : navEn
  // console.log(nav)
  const [projectTexte, setProjectTexte] = useState()
  const [currentProjectTexte, setCurrentProjectTexte] = useState()
  const [currentCat, setCurrentCat] = useState("")
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const _isMobile = window.innerWidth <= 768
    setIsMobile(_isMobile)

    const token = PubSub.subscribe("PROJECT_TEXTE", _updateProjectTexte)

    return () => PubSub.unsubscribe(token)
  }, [])

  useEffect(() => {
    if (template === "project") {
      _setCurrentCat()
    } else {
      setCurrentCat("")
    }
  }, [location])

  const _setCurrentCat = () => {
    // console.log(nav.data.body)
    const current = nav.data.body.filter(el => {
      const cat = el.primary.categorie.document.data.title.text
      const currentMenuItem = el.items.filter(_el =>
        _isCurrentMenuItem(_el.menu_item.document)
      )

      return currentMenuItem[0]
        ? currentMenuItem[0].menu_item.document.data.categorie.document.data
            .title.text === cat
        : false
    })
    // console.log(current)
    if (current)
      setCurrentCat(current[0].primary.categorie.document.data.title.text)
  }

  const _isCurrentMenuItem = item => {
    const { pathname } = location
    // console.log(item.uid, pathname)
    return pathname.indexOf(item.uid) > -1
  }

  const _updateProjectTexte = (e, d) => {
    // console.log(e, d)
    if (isMobile) return
    if (d) {
      setProjectTexte(d)
    } else {
      setProjectTexte(null)
    }
  }
  // console.log(nav.data)
  const _show = () => {
    if (template === "project") PubSub.publish("NAV_TOGGLE", true)
  }

  const _hide = () => {
    if (template === "project") PubSub.publish("NAV_TOGGLE", false)
  }

  const _updateTexte = texte => {
    setCurrentProjectTexte(texte)
  }
  const _restoreTexte = () => {
    setCurrentProjectTexte(null)
  }

  return (
    <>
      <nav className={`menu ${navActive ? "is-active" : ""}`}>
        <div className="container-fluid ">
          <div className="row ">
            <div className="col-md-3 col-xs-12">
              <ul
                className=" toggle-zone"
                onMouseEnter={_show}
                onMouseLeave={_hide}
              >
                {nav.data.body.map((el, i) => (
                  <li key={i} className="level-0">
                    <ul className="menu-items">
                      <div className="x xab">
                        <div
                          className={`categorie fS ${
                            currentCat ===
                            el.primary.categorie.document.data.title.text
                              ? "is-active"
                              : ""
                          }`}
                        >
                          {el.primary.categorie.document.data.title.text}
                        </div>
                        <ul className="menu-items-group">
                          {el.items.map(({ menu_item }, j) => (
                            <li
                              key={j}
                              onMouseEnter={() =>
                                _updateTexte(menu_item.document.data.texte)
                              }
                              onMouseLeave={_restoreTexte}
                            >
                              <Link
                                to={_localizedSlug(menu_item.document)}
                                activeClassName="is-active"
                              >
                                <div className="dot">•</div>
                                <div className="title fM link_gradient">
                                  {menu_item.document.data.title.text}
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </ul>
                  </li>
                ))}
              </ul>
              {/* <div className={`xs-only menu-toggle ${navActive ? "is-active" : ""}`} onClick={() => PubSub.publish("NAV_TOGGLE", !navActive)}>
              <i></i>
            </div> */}
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-3 col-xs-12">
              {projectTexte && !currentProjectTexte && (
                <div className="texte tal">
                  {RichText.render(projectTexte.raw)}
                </div>
              )}
              {currentProjectTexte && (
                <div className="texte tal">
                  {RichText.render(currentProjectTexte.raw)}
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      <div
        className={`xs-only menu-toggle ${navActive ? "is-active" : ""}`}
        onClick={() => PubSub.publish("NAV_TOGGLE", !navActive)}
      >
        <i></i>
      </div>
    </>
  )
}

export default Menu
