import React, { useContext, useState, useEffect } from "react"
// import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import PubSub from "pubsub-js"
import { WrapperContext } from "./Layout"
// import { linkResolver } from "../core/utils"
import Menu from "./Menu"
import Infos from "./Infos"
import LogoAccr from "./ui/LogoAccr"
import SliderIndex from "./slices/SliderIndex"
import { _getLocalizedText } from "../core/utils"

const Header = () => {
  const _WrapperContext = useContext(WrapperContext)
  const { lang, template } = _WrapperContext
  // console.log(lang)
  const [infosActive, setInfosActive] = useState()
  const [navActive, setNavActive] = useState()

  useEffect(() => {
    const tokenR = PubSub.subscribe("ROUTE_UPDATE", () => {
      _closeAll()
    })

    const tokenN = PubSub.subscribe("NAV_TOGGLE", (e, d) => {
      // console.log(d)
      setNavActive(d)
    })

    const tokenF = PubSub.subscribe("NAV_FADEIN", (e, d) => {
      console.log(e)
    })

    const tokenSliderEnd = PubSub.subscribe("SLIDER_END", () => {
      setNavActive(true)
    })
    const tokenScrollEnd = PubSub.subscribe("SCROLL_END", () => {
      // console.log("SCROLL_END SCROLL_END SCROLL_END")
      _navAnimeIn()
    })

    window.addEventListener("keydown", _onKey)

    return () => {
      PubSub.unsubscribe(tokenR)
      PubSub.unsubscribe(tokenF)
      PubSub.unsubscribe(tokenN)
      PubSub.unsubscribe(tokenSliderEnd)
      PubSub.unsubscribe(tokenScrollEnd)
      window.removeEventListener("keydown", _onKey)
    }
  }, [])

  useEffect(() => {
    // console.log(navActive, infosActive)
    if (navActive) document.body.classList.add("is-nav")
    if (infosActive) document.body.classList.add("is-infos")

    if (navActive || infosActive) {
      document.body.classList.add("is-dark")
    } else {
      document.body.classList.remove("is-dark")
      document.body.classList.remove("is-nav")
      document.body.classList.remove("is-infos")
    }
  }, [navActive, infosActive])

  // useEffect(() => {
  //   if(navActive){
  //     setTimeout(() => {
  //       document.querySelector("nav.menu .toggle-zone").style.opacity = 1
  //     }, 400)
  //   }else{
  //     document.querySelector("nav.menu .toggle-zone").style.opacity = 0
  //   }
  // }, [navActive])
  const _navAnimeIn = () => {
    //mobile
    document.querySelector(
      "nav.menu .toggle-zone, nav.menu .menu-toggle"
    ).style.opacity = 0
    setNavActive(true)
    setTimeout(() => {
      document.querySelector(
        "nav.menu .toggle-zone, nav.menu .menu-toggle"
      ).style.opacity = 1
    }, 400)
  }

  const _onKey = e => {
    if (e.key === "Escape") _closeAll()
  }

  const _closeAll = () => {
    setNavActive(false)
    setInfosActive(false)
  }

  const _getHomeUrl = () => (lang === "fr-fr" ? "/" : "/en")

  const homeBtn = () => (
    <Link className="btn-back" to={_getHomeUrl()}>
      <span className="link_gradient">{_getLocalizedText("home")}</span>
    </Link>
  )

  const projectsBtn = () => <button></button>
  return (
    <header>
      {/* <div id="bg"></div> */}
      <div className="inner fM xl-fl">
        <div className="row between-xs">
          <div className="col-xs">
            <div className="header--tl fMPlus">
              <button className="site-name ">
                <LogoAccr />
              </button>
              <button
                className="btn-infos "
                onClick={() => {
                  setNavActive(false)
                  setInfosActive(!infosActive)
                }}
              >
                <span className="link_gradient">
                  {infosActive
                    ? _getLocalizedText("about")
                    : _getLocalizedText("about")}
                </span>
              </button>
            </div>
          </div>
          <div className="col-xs tar">
            <div className="header--tr">
              {template === "project" ? (
                homeBtn()
              ) : (
                <button
                  className="btn-projects"
                  onClick={() => {
                    setInfosActive(false)
                    setNavActive(!navActive)
                  }}
                >
                  <span className="link_gradient">
                    {navActive
                      ? _getLocalizedText("home")
                      : _getLocalizedText("projects")}
                  </span>
                </button>
              )}
              {infosActive && (
                <button
                  className="btn-close"
                  onClick={() => setInfosActive(false)}
                >
                  <i></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <Menu navActive={navActive} />
      <Infos infosActive={infosActive} />
      {template === "project" && <SliderIndex />}
    </header>
  )
}

export default Header
