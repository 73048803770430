import React, { useEffect, useState } from 'react';
import PubSub from 'pubsub-js'

const SliderIndex = () => {
  const [current, setCurrent] = useState(0);
  const [length, setLength] = useState(0);

  useEffect(() => {
    const token = PubSub.subscribe("SLIDER_INDEX", (e, d) => {
      // console.log(d)
      // setCurrent(d.current)
      setCurrent(d.current)
      setLength(d.length)
    })

    return () => PubSub.unsubscribe(token)
  }, [])

  return (
    <div className="slider-index">
      {/* <span>{current}</span> <span className="sep"></span> <span>{length}</span> */}
      <span>{current}</span>
    </div>
  );
};

export default SliderIndex;