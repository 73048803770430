import React, { createContext, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import initReactFastclick from "react-fastclick"
import Header from "./Header"
import Footer from "./Footer"
import Cursor from "./ui/Cursor"

const WrapperContext = createContext()

const query = graphql`
  query {
    settings: prismicSettings {
      data {
        title {
          text
        }
        description {
          text
        }
        image {
          url
        }
        links {
          url {
            url
          }
          label {
            text
          }
        }
      }
    }
  }
`

const Layout = ({ children, location, pageContext: { template, lang } }) => {
  const { settings } = useStaticQuery(query)

  useEffect(() => {
    initReactFastclick()
  }, [])
  // console.log(location)
  return (
    <WrapperContext.Provider
      value={{
        settings: settings.data,
        lang,
        template,
        location,
      }}
    >
      <div id="page">
        <div id="bg"></div>
        <Header />
        <main>
          {children}
          <Cursor />
        </main>
        <Footer />
      </div>
    </WrapperContext.Provider>
  )
}

export { WrapperContext, Layout }
