import React, { useContext, useRef, useEffect } from "react"
import { WrapperContext } from "../components/Layout"

import i18n from "../../config/i18n"

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url)
  return decoded.substring(decoded.lastIndexOf("/") + 1)
}

export const _localizedSlug = node => {
  // console.log(node)
  if (!node.uid) return "/"
  const link = linkResolver(node)
  // console.log(link)
  return i18n[node.lang].default ? `${link}` : `/${i18n[node.lang].path}${link}`
}

export const linkResolver = doc => {
  // URL for a category type
  if (doc.type === "project") {
    return `/project/${doc.uid}`
  }
  // URL for a product type
  // if (doc.type === 'product') {
  //   return `/product/${doc.uid}`
  // }
  // URL for a page type
  // if (doc.type === 'homepage') {
  //   return `/`
  // }
  // Backup for all other types
  return "/"
}

export const _getLocalizedText = text => {
  const _WrapperContext = useContext(WrapperContext)
  const { lang } = _WrapperContext
  // console.log("locale",locale, text)
  return i18n[lang] && i18n[lang][text] ? i18n[lang][text] : text
}

// export const _localizedSlugWithPrefix = (node) => {

// }

// export const linkResolver = (doc) => {
//   // URL for a category type
//   // console.log(doc.type, doc.uid)
//   if(doc.type === "page")return `/${doc.uid}`;
//   if (doc) {
//     switch (doc.type) {
//       case 'velo':
//         return `/velo/${doc.uid}`;
//       case 'article':
//         return `/blog/${doc.uid}`;
//       case 'page':
//         return `/${doc.uid}`;
//       default:
//         if (doc.uid) {
//           return `/${doc.uid}`;
//         }
//         return `/${doc.type}`;
//     }
//   }

//   return '/';
// }

// In src/prismic-configuration.js

export const getYearByDate = d => {
  var dt = new Date(d)
  return dt.getFullYear()
}

export const DeviceWidthObject = {
  MobileSmall: { max: 320, min: 0 },
  MobileMedium: { max: 375, min: 321 },
  MobileLarge: { max: 767, min: 376 },
  Tablet: { max: 991, min: 768 },
  LaptopSmall: { max: 1024, min: 992 },
  LaptopLarge: { max: 1440, min: 1025 },
  LargerThanLaptop: { max: 2560, min: 1441 },
  LargeScreenMax: { max: 999999, min: 2561 },
}
export const IdDeviceBreakpointsByWidth = {
  laptop_max: 1440,
  laptop_min: 992,
  tablet_min: 768,
  tablet_max: 991,
  mobile_max: 767,
  default_min: 768, // Unrecognized device
}
export const IdMobileHeight = {
  mobileLandscape_min: 320,
  mobileLandscape_max: 425,
}

export const getConnectionSpeed = () => {
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection
  if (!connection) return null
  // console.log(connection)
  let connectionBand
  switch (connection.type) {
    case connection.CELL_3G:
      connectionBand = "medium-bandwidth"
      break
    case connection.CELL_2G:
      connectionBand = "low-bandwidth"
      break
    default:
      connectionBand = "high-bandwidth"
  }

  return connectionBand
}
// export const useEventListener = (eventName, handler, element = window) => {
//   // Create a ref that stores handler
//   const savedHandler = useRef();

//   // Update ref.current value if handler changes.
//   // This allows our effect below to always get latest handler ...
//   // ... without us needing to pass it in effect deps array ...
//   // ... and potentially cause effect to re-run every render.
//   useEffect(() => {
//     savedHandler.current = handler;
//   }, [handler]);

//   useEffect(
//     () => {
//       // Make sure element supports addEventListener
//       // On
//       const isSupported = element && element.addEventListener;
//       if (!isSupported) return;

//       // Create event listener that calls handler function stored in ref
//       const eventListener = event => savedHandler.current(event);

//       // Add event listener
//       element.addEventListener(eventName, eventListener);

//       // Remove event listener on cleanup
//       return () => {
//         element.removeEventListener(eventName, eventListener);
//       };
//     },
//     [eventName, element] // Re-run if eventName or element changes
//   );
// };
