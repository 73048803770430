// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-preview-js": () => import("./../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-page-home-jsx": () => import("./../src/templates/page-home.jsx" /* webpackChunkName: "component---src-templates-page-home-jsx" */),
  "component---src-templates-page-project-jsx": () => import("./../src/templates/page-project.jsx" /* webpackChunkName: "component---src-templates-page-project-jsx" */)
}

