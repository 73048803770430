//const website = require('./website')

module.exports = {
    'fr-fr': {
      default: true,
      label: 'Fr',
      path: 'fr',
      locale: 'fr-fr',
      siteLanguage: 'fr',
      ogLang: 'fr_FR',
      projects: 'projets',
      about: 'à propos',
      contact: 'contact',
      credits: 'crédits',
      design: 'Conception',
      imageProtectMessage: 'Cette image n\'est pas libre de droit<br/>©Anne-Sophie Auclerc.<br/>Tous droits réservés',
      imageProtectMessageAnnJo: 'Cette image n\'est pas libre de droit<br/>©Ann et Jo.<br/>Tous droits réservés'
    },
    'en-gb': {
      default: false,
      label: 'En',
      path: 'en',
      locale: 'en-gb',
      siteLanguage: 'en',
      ogLang: 'en_GB' ,
      projects: 'projects',
      about: 'about',
      contact: 'contact',
      credits: 'credits',
      design: 'Design',
      imageProtectMessage: 'This image is Copyright<br/>©Anne-Sophie Auclerc.<br/>All right reserved',
      imageProtectMessageAnnJo: 'This image is Copyright<br/>©Ann et Jo.<br/>All right reserved'
    }
  }
  